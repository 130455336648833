import { IonButton } from '@ionic/react';
import React from 'react'
import { useHistory } from 'react-router';
import { API_URL } from '../../actions/settings';

async function logout(token: string) {
  return fetch(`${API_URL}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((res) => {
    if (res.ok) {
      return true;
    } else {
      return false;
    }
  });
}


const Logout = () => {
    const history = useHistory()

    const handleLogout = async () => {
      const token = `Token ${(localStorage.getItem("token")||'').slice(1, -1)}`;
      await logout(token)
      localStorage.clear();
      history.push("/login");
      window.location.reload()
    };
  return (
  <div className='logout-button'>
        <IonButton  expand='block' color='medium' onClick={handleLogout}>Logout</IonButton>
        <div>Copyright © 2019 - 2024</div>
        <div>Alpha Controls & Services, LLC All Rights Reserved.</div>
        <a href="/terms_and_conditions" target="_blank">Terms & Conditions</a> - <a href="/privacy" target="_blank"> Privacy Policy </a>
        </div>)
}

export default Logout