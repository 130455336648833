import {
  IonButton,
  IonContent,
  IonPopover,
  IonSelect,
  IonSelectOption,
  IonSpinner,
} from "@ionic/react";
import React, { useState } from "react";
import { API_URL } from "../../../actions/settings";
import NotificationPopUp from "../../extras/NotificationPopUp";

interface Props {
  id: string;
  name: string;
}

async function downloadInvoice(
  token: string,
  id: string,
  month: number,
  year: number
) {
  return fetch(`${API_URL}/8020-invoice`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify({
      building: id,
      month: month,
      year: year,
    }),
  }).then(async (res: any) => {
    if (res.ok) {
      return res.blob();
    } else {
      const data = await res.json();
      return {
        error: data.error,
      };
    }
  });
}

const Download8020InvoiceButton: React.FC<Props> = ({ id, name }) => {
  const [loading, setLoading] = useState(false);
  const yr = new Date().getFullYear();
  const today = new Date();
  today.setMonth(today.getMonth() - 1);
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [year, setYear] = useState(today.getFullYear());

  const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  const handleDownload = async () => {
    setLoading(true);
    const tkn: any = localStorage.getItem("token");
    const token = `Token ${tkn.slice(1, -1)}`;
    const res = await downloadInvoice(token, id, month, year);
    if ("error" in res) {
      NotificationPopUp("error", res["error"], "Error Generating Invoice");
    } else {
      const url = window.URL.createObjectURL(res);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}_${month}_${year}_Invoice.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode!.removeChild(link);
    }
    setLoading(false);
  };

  return (
    <div>
      <IonButton
        id="download-8020-invoice"
        fill="solid"
        size="small"
        color="medium"
      >
        80/20 Invoice
      </IonButton>
      <IonPopover
        trigger="download-8020-invoice"
        size="auto"
        side="bottom"
        alignment="start"
        // showBackdrop={false}
      >
        <IonContent color="medium" style={{ padding: "1rem" }}>
          <div style={{ width: "100%", textAlign: "center", padding: "1rem" }}>
            Enter Month and Year to Generate and Download Invoice.
          </div>
          {/* <IonDatetime presentation="month-year"></IonDatetime> */}
          <div>
            <IonSelect
              label="Month"
              interface="popover"
              value={month}
              placeholder="Month"
              onIonChange={(e) => {
                setMonth(e.detail.value!);
              }}
            >
              <IonSelectOption value={1}>1 - January</IonSelectOption>
              <IonSelectOption value={2}>2 - February</IonSelectOption>
              <IonSelectOption value={3}>3 - March</IonSelectOption>
              <IonSelectOption value={4}>4 - April</IonSelectOption>
              <IonSelectOption value={5}>5 - May</IonSelectOption>
              <IonSelectOption value={6}>6 - June</IonSelectOption>
              <IonSelectOption value={7}>7 - July</IonSelectOption>
              <IonSelectOption value={8}>8 - August</IonSelectOption>
              <IonSelectOption value={9}>9 - September</IonSelectOption>
              <IonSelectOption value={10}>10 - October</IonSelectOption>
              <IonSelectOption value={11}>11 - November</IonSelectOption>
              <IonSelectOption value={12}>12 - December</IonSelectOption>
            </IonSelect>
            <IonSelect
              label="Year"
              interface="popover"
              placeholder="Year"
              value={year}
              onIonChange={(e) => {
                setYear(e.detail.value!);
              }}
            >
              {years.map((i: number) => (
                <IonSelectOption key={i} value={yr - i}>
                  {yr - i}
                </IonSelectOption>
              ))}
            </IonSelect>
          </div>
          <IonButton
            onClick={() => handleDownload()}
            expand="block"
            disabled={loading}
          >
            {loading ? <IonSpinner /> : "Download"}
          </IonButton>
        </IonContent>
      </IonPopover>
    </div>
  );
};

export default Download8020InvoiceButton;
