import { Redirect } from 'react-router';
import { API_URL } from '../../actions/settings';
import { useAppDispatch } from '../../Hooks';
import { setStateUser } from '../../reducers/UserReducer';
import NotificationPopUp from '../extras/NotificationPopUp';

async function getUserApi(token:any) {
  return fetch(`${API_URL}/user`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization':token
    }

  })
    .then(data => data.json().then(res => ({"status":data.status, "user":res})))
 }

const getUserInfo = async (token:any,setToken:any, dispatch:any) => {
  const {status, user} = await getUserApi(`Token ${token}`)
  if(status === 200 ){
    dispatch(setStateUser(user))
  }
  if(status === 401){
    console.log('unauthorized')
    localStorage.removeItem('token')
    NotificationPopUp('error',"Your session has expired. Please log in again.","Session Expired",1000)
    setToken(null)
    return <Redirect to="/login"/>
  }
  if(status !==200 && status !== 401){
    console.log('Error')
    localStorage.removeItem('token')
    NotificationPopUp('Error',"Internal Error, Try Again.","Internal Error")
    setToken(null)
    return <Redirect to="/login"/>
  }
}

const StoreUser = async (token:any,setToken:any) => {
  const dispatch = useAppDispatch()
  if(token){
  return await getUserInfo(token,setToken,dispatch)}
};

export default StoreUser;