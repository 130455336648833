import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonPopover,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import { API_URL, REACT_APP_GOOGLE_MAPS_KEY } from "../../actions/settings";
import "./PropertyForm.css";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import SearchSelectInput from "../extras/SearchSelectInput";
import IDSearchSelectInput from "../extras/IDSearchSelectInput";
import CheckRole from "../extras/CheckRole";
import { useAppSelector } from "../../Hooks";
import { selectUser } from "../../reducers/UserReducer";
import NotificationPopUp from "../extras/NotificationPopUp";
import useForm from "../../utils/useForm";
import S3Bucket from "../extras/S3Bucket";
import useHandleState from "../../utils/useHandleState";
import AE2Input from "../elements/AE2Input";

interface Params {
  id: string;
}

interface types {
  uuid: any;
  property_type: number;
  sub_type_name: string;
}

async function createBuilding(data: any, token: string) {
  return fetch(`${API_URL}/buildings`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

async function getAllCampuses(token: any) {
  return fetch(`${API_URL}/campuses`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

async function getAllPrimaryFunctions(token: any) {
  return fetch(`${API_URL}/energystar/primaryfunctions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

async function getAllSubTypes(token: any) {
  return fetch(`${API_URL}/subtypes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const county_choices: any = {
  IL: [
    ["Adams", "Adams"],
    ["Alexander", "Alexander"],
    ["Bond", "Bond"],
    ["Boone", "Boone"],
    ["Brown", "Brown"],
    ["Bureau", "Bureau"],
    ["Calhoun", "Calhoun"],
    ["Carroll", "Carroll"],
    ["Cass", "Cass"],
    ["Champaign", "Champaign"],
    ["Christian", "Christian"],
    ["Clark", "Clark"],
    ["Clay", "Clay"],
    ["Clinton", "Clinton"],
    ["Coles", "Coles"],
    ["Cook", "Cook"],
    ["Crawford", "Crawford"],
    ["Cumberland", "Cumberland"],
    ["DeKalb", "DeKalb"],
    ["DeWitt", "DeWitt"],
    ["Douglas", "Douglas"],
    ["DuPage", "DuPage"],
    ["Edgar", "Edgar"],
    ["Edwards", "Edwards"],
    ["Effingham", "Effingham"],
    ["Fayette", "Fayette"],
    ["Ford", "Ford"],
    ["Franklin", "Franklin"],
    ["Fulton", "Fulton"],
    ["Gallatin", "Gallatin"],
    ["Greene", "Greene"],
    ["Grundy", "Grundy"],
    ["Hamilton", "Hamilton"],
    ["Hancock", "Hancock"],
    ["Hardin", "Hardin"],
    ["Henderson", "Henderson"],
    ["Henry", "Henry"],
    ["Iroquois", "Iroquois"],
    ["Jackson", "Jackson"],
    ["Jasper", "Jasper"],
    ["Jefferson", "Jefferson"],
    ["Jersey", "Jersey"],
    ["Jo Daviess", "Jo Daviess"],
    ["Johnson", "Johnson"],
    ["Kane", "Kane"],
    ["Kankakee", "Kankakee"],
    ["Kendall", "Kendall"],
    ["Knox", "Knox"],
    ["Lake", "Lake"],
    ["LaSalle", "LaSalle"],
    ["Lawrence", "Lawrence"],
    ["Lee", "Lee"],
    ["Livingston", "Livingston"],
    ["Logan", "Logan"],
    ["Macon", "Macon"],
    ["Macoupin", "Macoupin"],
    ["Madison", "Madison"],
    ["Marion", "Marion"],
    ["Marshall", "Marshall"],
    ["Mason", "Mason"],
    ["Massac", "Massac"],
    ["McDonough", "McDonough"],
    ["McHenry", "McHenry"],
    ["McLean", "McLean"],
    ["Menard", "Menard"],
    ["Mercer", "Mercer"],
    ["Monroe", "Monroe"],
    ["Montgomery", "Montgomery"],
    ["Morgan", "Morgan"],
    ["Moultrie", "Moultrie"],
    ["Ogle", "Ogle"],
    ["Peoria", "Peoria"],
    ["Perry", "Perry"],
    ["Piatt", "Piatt"],
    ["Pike", "Pike"],
    ["Pope", "Pope"],
    ["Pulaski", "Pulaski"],
    ["Putnam", "Putnam"],
    ["Randolph", "Randolph"],
    ["Richland", "Richland"],
    ["Rock Island", "Rock Island"],
    ["Saline", "Saline"],
    ["Sangamon", "Sangamon"],
    ["Schuyler", "Schuyler"],
    ["Scott", "Scott"],
    ["Shelby", "Shelby"],
    ["St. Clair", "St. Clair"],
    ["Stark", "Stark"],
    ["Stephenson", "Stephenson"],
    ["Tazewell", "Tazewell"],
    ["Union", "Union"],
    ["Vermilion", "Vermilion"],
    ["Wabash", "Wabash"],
    ["Warren", "Warren"],
    ["Washington", "Washington"],
    ["Wayne", "Wayne"],
    ["White", "White"],
    ["Whiteside", "Whiteside"],
    ["Will", "Will"],
    ["Williamson", "Williamson"],
    ["Winnebago", "Winnebago"],
    ["Woodford", "Woodford"],
  ],
  WI: [
    ["Adams", "Adams"],
    ["Ashland", "Ashland"],
    ["Barron", "Barron"],
    ["Bayfield", "Bayfield"],
    ["Brown", "Brown"],
    ["Buffalo", "Buffalo"],
    ["Burnett", "Burnett"],
    ["Calumet", "Calumet"],
    ["Chippewa", "Chippewa"],
    ["Clark", "Clark"],
    ["Columbia", "Columbia"],
    ["Crawford", "Crawford"],
    ["Dane", "Dane"],
    ["Dodge", "Dodge"],
    ["Door", "Door"],
    ["Douglas", "Douglas"],
    ["Dunn", "Dunn"],
    ["Eau Claire", "Eau Claire"],
    ["Florence", "Florence"],
    ["Fond du Lac", "Fond du Lac"],
    ["Forest", "Forest"],
    ["Grant", "Grant"],
    ["Green", "Green"],
    ["Green Lake", "Green Lake"],
    ["Iowa", "Iowa"],
    ["Iron", "Iron"],
    ["Jackson", "Jackson"],
    ["Jefferson", "Jefferson"],
    ["Juneau", "Juneau"],
    ["Kenosha", "Kenosha"],
    ["Kewaunee", "Kewaunee"],
    ["La Crosse", "La Crosse"],
    ["Lafayette", "Lafayette"],
    ["Langlade", "Langlade"],
    ["Lincoln", "Lincoln"],
    ["Manitowoc", "Manitowoc"],
    ["Marathon", "Marathon"],
    ["Marinette", "Marinette"],
    ["Marquette", "Marquette"],
    ["Menominee", "Menominee"],
    ["Milwaukee", "Milwaukee"],
    ["Monroe", "Monroe"],
    ["Oconto", "Oconto"],
    ["Oneida", "Oneida"],
    ["Outagamie", "Outagamie"],
    ["Ozaukee", "Ozaukee"],
    ["Pepin", "Pepin"],
    ["Pierce", "Pierce"],
    ["Polk", "Polk"],
    ["Portage", "Portage"],
    ["Price", "Price"],
    ["Racine", "Racine"],
    ["Richland", "Richland"],
    ["Rock", "Rock"],
    ["Rusk", "Rusk"],
    ["Sauk", "Sauk"],
    ["Sawyer", "Sawyer"],
    ["Shawano", "Shawano"],
    ["Sheboygan", "Sheboygan"],
    ["St. Croix", "St. Croix"],
    ["Taylor", "Taylor"],
    ["Trempealeau", "Trempealeau"],
    ["Vernon", "Vernon"],
    ["Vilas", "Vilas"],
    ["Walworth", "Walworth"],
    ["Washburn", "Washburn"],
    ["Washington", "Washington"],
    ["Waukesha", "Waukesha"],
    ["Waupaca", "Waupaca"],
    ["Waushara", "Waushara"],
    ["Winnebago", "Winnebago"],
    ["Wood", "Wood"],
  ],
};
const construction_choices: string[][] = [
  ["Existing", "Existing"],
  ["Project", "Project"],
  ["Test", "Test"],
];

const occupancy_choices: string[][] = [
  ["100", "100"],
  ["95", "95"],
  ["90", "90"],
  ["85", "85"],
  ["80", "80"],
  ["75", "75"],
  ["70", "70"],
  ["65", "65"],
  ["60", "60"],
  ["55", "55"],
  ["50", "50"],
  ["45", "45"],
  ["40", "40"],
  ["35", "35"],
  ["30", "30"],
  ["25", "25"],
  ["20", "20"],
  ["15", "15"],
  ["10", "10"],
  ["5", "5"],
  ["0", "0"],
];

const state_choices: string[][] = [
  ["IL", "Illinois"],
  ["WI", "Wisconsin"],
  ["AL", "Alabama"],
  ["AR", "Arkansas"],
  ["AS", "American Samoa"],
  ["AZ", "Arizona"],
  ["CA", "California"],
  ["CO", "Colorado"],
  ["CT", "Connecticut"],
  ["DC", "District of Columbia"],
  ["DE", "Delaware"],
  ["FL", "Florida"],
  ["GA", "Georgia"],
  ["GU", "Guam"],
  ["HI", "Hawaii"],
  ["IA", "Iowa"],
  ["ID", "Idaho"],
  ["IN", "Indiana"],
  ["KS", "Kansas"],
  ["KY", "Kentucky"],
  ["LA", "Louisiana"],
  ["MA", "Massachusetts"],
  ["MD", "Maryland"],
  ["ME", "Maine"],
  ["MH", "Marshall Islands"],
  ["MI", "Michigan"],
  ["MN", "Minnesota"],
  ["MO", "Missouri"],
  ["MP", "Northern Mariana Islands"],
  ["MS", "Mississippi"],
  ["MT", "Montana"],
  ["NC", "North Carolina"],
  ["ND", "North Dakota"],
  ["NE", "Nebraska"],
  ["NH", "New Hampshire"],
  ["NJ", "New Jersey"],
  ["NN", "Navajo Nation"],
  ["NV", "Nevada"],
  ["NY", "New York"],
  ["OH", "Ohio"],
  ["OK", "Oklahoma"],
  ["OR", "Oregon"],
  ["PA", "Pennsylvania"],
  ["PI", "Pacific Island"],
  ["PR", "Puerto Rico"],
  ["RI", "Rhode Island"],
  ["SC", "South Carolina"],
  ["SD", "South Dakota"],
  ["TN", "Tennessee"],
  ["TT", "Trust Territories"],
  ["TX", "Texas"],
  ["UM", "U.S. Minor Outlying Islands"],
  ["UT", "Utah"],
  ["VA", "Virginia"],
  ["VI", "Virgin Islands"],
  ["VT", "Vermont"],
  ["WA", "Washington"],
  ["WQ", "Wake Island"],
  ["WV", "West Virginia"],
  ["WY", "Wyoming"],
  ["AB", "Alberta"],
  ["BC", "British Columbia"],
  ["MB", "Manitoba"],
  ["NB", "New Brunswick"],
  ["NL", "Newfoundland"],
  ["NS", "Nova Scotia"],
  ["NT", "Northern Territories"],
  ["NU", "Nunavut"],
  ["ON", "Ontario"],
  ["PE", "Prince Edward Island"],
  ["QC", "Quebec"],
  ["SK", "Saskatchewan"],
  ["YT", "Yukon"],
];

const country_choices: string[][] = [
  ["US", "United States"],
  ["CA", "Canada"],
];

// const getBuildings = async (token:any, setCampuses:any) => {
//         const campuses = await getAllCampuses(`Token ${token.slice(1,-1)}`)
//         setCampuses(campuses)
//     }
const getPrimaryFunctions = async (token: any, setPrimaryfunctions: any) => {
  const primaryfunctions = await getAllPrimaryFunctions(
    `Token ${token.slice(1, -1)}`
  );
  setPrimaryfunctions(primaryfunctions);
};
const getSubTypes = async (token: any, setSubTypes: any) => {
  const subtypes = await getAllSubTypes(`Token ${token.slice(1, -1)}`);
  setSubTypes(subtypes);
};

const PropertyForm: React.FC = () => {
  const history = useHistory();
  let params = new URLSearchParams(window.location.search);
  const user = useAppSelector(selectUser);
  const campus_id = params.get("campus");
  const [image, setImg] = useState<any>("");
  const [imageUrl, setImgUrl] = useState<any>("");

  const { placesService, placePredictions, getPlacePredictions } =
    usePlacesService({
      apiKey: REACT_APP_GOOGLE_MAPS_KEY,
    });

  const handlePlace = (item: any) => {
    placesService?.getDetails(
      {
        placeId: item.place_id,
      },
      (placeDetails: any) => {
        handleFormChange(
          placeDetails.address_components.filter((r: any) =>
            r.types.includes("postal_code")
          )[0].long_name,
          "building_zipcode"
        );
        handleFormChange(
          `${
            placeDetails.address_components.filter((r: any) =>
              r.types.includes("street_number")
            )[0].long_name
          } ${
            placeDetails.address_components.filter((r: any) =>
              r.types.includes("route")
            )[0].short_name
          }`,
          "building_address"
        );
        handleFormChange(
          placeDetails.address_components.filter((r: any) =>
            r.types.includes("locality")
          )[0].long_name,
          "building_city"
        );
        handleFormChange(
          placeDetails.address_components.filter((r: any) =>
            r.types.includes("administrative_area_level_1")
          )[0].short_name,
          "building_state"
        );
      }
    );
  };

  const startingForm = {
    cust_uuid: "",
    campus_uuid: campus_id || "",
    building_name: null,
    building_description: "",
    building_address: null,
    building_address_2: null,
    building_city: null,
    building_state: "",
    building_zipcode: null,
    building_county: "",
    building_sq_ft: null,
    building_type: "",
    building_sub_type: null,
    building_incentive: null,
    add_estar: false,
    add_ae2: true,
    in_estar: false,
    estar_country: "US",
    estar_year_built: null,
    estar_construction_status: "",
    estar_occupancy: "",
    estar_is_federal_property: false,
    estar_federal_owner: null,
    estar_id: null,
    number_of_buildings: 1,
    building_fan_cycle: "0",
    campus_optimizer: false,
    co_start_date: null,
    fc_start_date: null,
    foa: false,
    kpi: false,
    project_fee:""
  };
  const startingErrors: any = {
    campus_uuid: "",
    building_name: "",
    building_description: "",
    building_address: "",
    building_address_2: "",
    building_city: "",
    building_state: "",
    building_zipcode: "",
    building_county: "",
    building_sq_ft: "",
    building_type: "",
    building_sub_type: "",
    building_incentive: "",
    add_estar: "",
    add_ae2: "",
    in_estar: "",
    estar_country: "",
    estar_year_built: "",
    estar_construction_status: "",
    estar_occupancy: "",
    estar_is_federal_owner: "",
    estar_federal_owner: "",
    estar_id: "",
    number_of_buildings: "",
    building_fan_cycle: "",
    campus_optimizer: "",
    co_start_date: "",
    fc_start_date: "",
    foa: false,
    kpi: false,
    project_fee:""
  };

  const validate = (name: string, val: any) => {
    const regExp = /[a-zA-Z]/g;
    switch (name) {
      case "building_name":
        if (val === null || val.length <= 1) {
          return { building_name: "Name Must Be longer than 2 letters" };
        } else {
          return { building_name: "" };
        }

      case "building_address":
        if (val === null || val.length === 0 || val === " ") {
          return { building_address: "Address cannot be empty" };
        } else {
          return { building_address: "" };
        }
      case "building_city":
        if (val === null || val.length === 0) {
          return { building_city: "City cannot be empty" };
        } else {
          return { building_city: "" };
        }

      case "building_state":
        if (val === null || val.length === 0) {
          return { building_state: "Select A State" };
        } else if (
          !state_choices.some((st) => st.includes(val)) ||
          val.length >= 3
        ) {
          return { building_state: "Select State From The List" };
        } else {
          return { building_state: "" };
        }
      // case "building_county":
      //   if (val === null || val.length === 0) {
      //     return { building_county: "County Cannot be Empty" };
      //   } else {
      //     return { building_county: "" };
      //   }
      case "building_zipcode":
        if (val === null || val.length === 0) {
          return { building_zipcode: "Zip Code cannot be empty" };
        } else if (regExp.test(val)) {
          return { building_zipcode: "Zip Code cannot contain letters" };
        } else {
          return { building_zipcode: "" };
        }
      case "building_sq_ft":
        if (val === null || val < 0) {
          return {
            building_sq_ft: "Building Square Feet must be greater than 0",
          };
        } else {
          return { building_sq_ft: "" };
        }
      case "building_sub_type":
        val = form.building_sub_type;
        if (Number(val) === 30 || Number(val) === 48) {
          return { building_sub_type: "Select Sub Type" };
        } else {
          return { building_sub_type: "" };
        }
      case "building_type":
        if (val === null || val === "") {
          return { building_type: "Select Property Type" };
        } else if (
          primaryfunctions &&
          !primaryfunctions.some((st: any) => st.id === val)
        ) {
          return { building_type: "Select Property Type From List" };
        } else {
          return { building_type: "" };
        }
      case "campus_uuid":
        if (val === null || val === "") {
          return { campus_uuid: "" };
        } else if (campuses && !campuses.some((st: any) => st.uuid === val)) {
          return { campus_uuid: "Select Campus From List" };
        } else {
          return { campus_uuid: "" };
        }
      case "estar_id":
        if (form.in_estar && (val === null || val < 0)) {
          return { estar_id: "Enter a valid Energy star id" };
        } else {
          return { estar_id: "" };
        }
      case "estar_year_built":
        if (
          form.add_estar &&
          (val === null || val < 1700 || val > getCurrentYear())
        ) {
          return { estar_year_built: "Enter a valid year " };
        } else {
          return { estar_year_built: "" };
        }
      case "estar_country":
        if (form.add_estar && (val === "" || val === null)) {
          return { estar_country: "Country cannot be empty " };
        } else if (
          form.add_estar &&
          (!country_choices.some((st) => st.includes(val)) || val.length >= 3)
        ) {
          return { estar_country: "Select Country From The List" };
        } else {
          return { estar_country: "" };
        }
      case "estar_construction_status":
        if (form.add_estar && (val === null || val === "")) {
          return {
            estar_construction_status: "Construction Status cannot be empty.",
          };
        } else if (
          form.add_estar &&
          !construction_choices.some((st) => st.includes(val))
        ) {
          return {
            estar_construction_status:
              "Select Construction Status From The List",
          };
        } else {
          return { estar_construction_status: "" };
        }
      case "estar_occupancy":
        if (form.add_estar && val === null) {
          return { estar_occupancy: "Occupancy cannot be empty." };
        } else if (
          form.add_estar &&
          (!occupancy_choices.some((st) => st.includes(val)) || val.length >= 4)
        ) {
          return { estar_occupancy: "Select Percentage From The List" };
        } else {
          return { estar_occupancy: "" };
        }
      case "number_of_buildings":
        if (form.add_estar && (val === null || val < 1)) {
          return {
            number_of_buildings: "Number of Buildings must be 1 or greater",
          };
        } else {
          return { number_of_buildings: "" };
        }
      case "estar_federal_owner":
        if (form.estar_is_federal_property && val === null) {
          return { estar_federal_owner: "Federal owner cannot be empty." };
        } else {
          return { estar_federal_owner: "" };
        }
      case "foa":
        if (val === null) {
          return { foa: "Error" };
        } else {
          return { foa: "" };
        }
      case "kpi":
        if (val === null) {
          return { kpi: "Error" };
        } else {
          return { kpi: "" };
        }
      case "building_fan_cycle":
        if (form.building_fan_cycle && val < 0) {
          return { building_fan_cycle: "Fan Cycle Must be a positive number" };
        } else if (form.building_fan_cycle && val > 100) {
          return { building_fan_cycle: "Fan Cycle cannot exceed 100" };
        } else {
          return { building_fan_cycle: "" };
        }
      case "project_fee":
          if (val < 0) {
            return {
              project_fee: "Total project fee must be a positive number.",
            };
          } else {
            return { project_fee: "" };
          }

      default:
        break;
    }
  };

  const { form, handleFormChange, errors, resetForm, validateForm } = useForm(
    startingForm,
    startingErrors,
    validate
  );
  const [campuses, handleCampuses] = useHandleState([]);
  const [primaryfunctions, setPrimaryfunctions] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const { id } = useParams<Params>();
  const [loaded, setLoaded] = useState(false);
  const address2Ref = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    // getBuildings(token,setCampuses)
    handleCampuses(token, getAllCampuses);
    getPrimaryFunctions(token, setPrimaryfunctions);
    getSubTypes(token, setSubTypes);
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    const token: any = localStorage.getItem("token");
    const valid = validateForm() || false;
    form.cust_uuid = id;
    if (valid) {
      const res = await createBuilding(form, `Token ${token.slice(1, -1)}`);
      if (res.status === "Created") {
        if (image) {
          S3Bucket.UploadImage(
            "thumbnails/pics",
            res.data.uuid,
            image,
            "building"
          );
        }
        if (form.add_ae2) {
          resetForm();
          history.push(`/?id=${res.data.uuid}&sec=buildings`);
        } else {
          resetForm();
          history.push(`/energystar/properties/${res.data.uuid}`);
        }
        NotificationPopUp("success", "Building Created");
      } else {
        NotificationPopUp(
          "error",
          res.detail || res.ERROR,
          res.error || "Error"
        );
      }
    }
  };

  const clickAddress = (input: any, e: any) => {
    if (input.length === 1) {
      e.target.click();
    }
  };

  const onImageChange = (e: any) => {
    const img = e.target.files[0];
    setImg(img);
    setImgUrl(URL.createObjectURL(img));
  };

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  if (!loaded || user.first_name === "") {
    return (
      <IonLoading
        cssClass="my-custom-class"
        isOpen={!loaded}
        message={"Loading..."}
        spinner="bubbles"
      />
    );
  }

  if (!CheckRole(user, ["Admin", "Supervisor"])) {
    return <Redirect to="/403" />;
  }

  return (
    <form>
      <IonRow className="form-title-row">
        <h2>Add Property to AE2/Energy Star</h2>{" "}
        <IonButton
          className="cancel-btn"
          size="small"
          color="danger"
          href={`/?id=${id}&sec=customers`}
        >
          {" "}
          Cancel{" "}
        </IonButton>
      </IonRow>
      <IonRow>
        <IonItem lines="none">
          <div>
          <IonText >Image: </IonText>
          <IonRow>
            {imageUrl ? (
              <IonImg className="preview-image" src={imageUrl} />
            ) : (
              ""
            )}
            <input type="file" accept="image/*" onChange={onImageChange} />
          </IonRow>
          </div>
        </IonItem>
      </IonRow>
      <IonItem>
        <IonCheckbox
          checked={form.add_ae2}
          mode="ios"
          slot="start"
          aria-label="Add Property to AE2"
          onIonChange={(e: any) =>
            handleFormChange(e.detail.checked, "add_ae2")
          }
        >
          ADD Property to AE2
        </IonCheckbox>
      </IonItem>
      <IonItem lines="none">
        <AE2Input
          label='Building Name' labelPlacement='floating'
          className={errors.building_name === "" ? "valid" : "ion-invalid ion-touched"}
          aria-labelledby="Building Name"
          value={form.building_name}
          placeholder="Name"
          onIonInput={(e: any) =>
            handleFormChange(e.detail.value!, "building_name")
          }
          errorText={errors.building_name}
        />
      </IonItem>
      <IonItem >
        <IonTextarea
          label='Building Description' labelPlacement='floating'
          className={errors.building_description === "" ? "valid" : "ion-invalid ion-touched"}
          aria-labelledby="Building Description"
          errorText={errors.building_description}
          auto-grow
          value={form.building_description}
          placeholder="Description"
          onIonInput={(e: any) =>
            handleFormChange(e.detail.value!, "building_description")
          }
          style={{
            width:'100%'
          }}
        >
          {" "}
        </IonTextarea>

      </IonItem>
      <IonItem lines="none">
        <IonRow className="address-row">
          <IonCol size="6">
            <IonInput
            label='Address' labelPlacement='floating'
              id="address"
              className={errors.building_address === "" ? "valid" : "ion-invalid ion-touched"}
              aria-labelledby="address"
              value={form.building_address}
              placeholder="Address"
              onIonInput={(e: any) => {
                handleFormChange(e.detail.value!, "building_address");
                getPlacePredictions({ input: e.detail.value! });
                clickAddress(e.detail.value!, e);
              }}
              errorText={errors.building_address}
            >
              {" "}
            </IonInput>
            <IonPopover
              trigger="address"
              className="address-list"
              reference="trigger"
              dismissOnSelect
              showBackdrop={false}
              keyboardClose={false}
              size="cover"
            >
              <IonContent>
                <IonList>
                  {placePredictions.map((item) => (
                    <IonItem
                      className="address-item"
                      button
                      key={item.place_id}
                      onClick={() => handlePlace(item)}
                    >
                      <IonLabel color="dark">{item.description}</IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </IonContent>
            </IonPopover>
          </IonCol>
          <IonCol size="6">
            <IonInput
              ref={address2Ref}
              className={errors.building_address_2 === "" ? "valid" : "ion-invalid ion-touched"}
              label="Address 2"
              labelPlacement="floating"
              aria-labelledby="address 2"
              value={form.building_address_2}
              placeholder="Address 2"
              onIonInput={(e: any) =>
                handleFormChange(e.detail.value!, "building_address_2")
              }
              errorText={errors.building_address_2}
            >
              {" "}
            </IonInput>
          </IonCol>
        </IonRow>
      </IonItem>
      <IonRow className="address-row-2">
        <IonCol size="6" sizeMd="4">
          <IonInput
            className={errors.building_city === "" ? "valid" : "ion-invalid ion-touched"}
            value={form.building_city}
            label="City"
            labelPlacement="floating"
            aria-labelledby="city"
            placeholder="City"
            onIonInput={(e: any) =>
              handleFormChange(e.detail.value!, "building_city")
            }
            errorText={errors.building_city}
          />
        </IonCol>
        <IonCol size="6" sizeMd="4">
          <SearchSelectInput
            list={state_choices}
            errors={errors.building_state}
            label="State"
            form={form.building_state}
            handleFormChange={handleFormChange}
            formName={"building_state"}
            placeholder={"State"}
          />
        </IonCol>
        <IonCol size="6" sizeMd="4">
          <IonInput
            className={errors.building_zipcode === "" ? "valid" : "ion-invalid ion-touched"}
            label='Zip Code'
            labelPlacement="floating"
            aria-labelledby="Zip Code"
            value={form.building_zipcode}
            placeholder="Zip Code"
            onIonInput={(e: any) =>
              handleFormChange(e.detail.value!, "building_zipcode")
            }
            errorText={errors.building_zipcode}
          >
            {" "}
          </IonInput>
        </IonCol>
        <IonCol size="6" sizeMd="4">
          {form.building_state === "IL" || form.building_state === "WI" ? (
            <SearchSelectInput
              list={county_choices[form.building_state]}
              label="County"
              errors={errors.building_county}
              form={form.building_county}
              handleFormChange={handleFormChange}
              formName={"building_county"}
              placeholder={"County"}
            />
          ) : (
            <IonInput
              className={errors.building_county === "" ? "valid" : "ion-invalid ion-touched"}
              label="County"
              labelPlacement="floating"
              aria-labelledby="county"
              value={form.building_county}
              placeholder="County"
              onIonInput={(e: any) =>
                handleFormChange(e.detail.value!, "building_county")
              }
              errorText={errors.building_county}
            >
              {" "}
            </IonInput>
          )}
        </IonCol>
      </IonRow>

      <IonRow>
        <IonCol>
          <IonItem lines="none">
            <IonInput
            label='Gross Floor Area' labelPlacement='floating'
              className={errors.building_sq_ft === "" ? "valid" : "ion-invalid ion-touched"}
              aria-labelledby="Gross Floor Area"
              type="number"
              value={form.building_sq_ft}
              placeholder="Gross Floor Area"
              onIonInput={(e: any) =>
                handleFormChange(e.detail.value!, "building_sq_ft")
              }
              errorText={errors.building_sq_ft}
            ></IonInput>
          </IonItem>
        </IonCol>
        <IonCol hidden={campus_id !== null}>
          <IonItem lines="none">
            <IDSearchSelectInput
              dict={
                campuses.length > 0
                  ? campuses.filter((c: any) => c.cust_uuid.uuid === id)
                  : []
              }
              label="Campus Associated With Building"
              dict_key={"uuid"}
              value={"campus_name"}
              errors={errors.campus_uuid}
              form={form}
              handleFormChange={handleFormChange}
              formName={"campus_uuid"}
              placeholder={"Campus"}
            />
          </IonItem>
        </IonCol>
        <IonCol>
          <IonItem>
            <IonInput
            label='Incentive' labelPlacement='floating'
              className={errors.building_incentive === "" ? "valid" : "ion-invalid ion-touched"}
              type="number"
              value={form.building_incentive}
              aria-labelledby="Incentive"
              onIonInput={(e: any) =>
                handleFormChange(e.detail.value!, "building_incentive")
              }
              errorText={errors.building_incentive}
            ></IonInput>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="6" sizeMd="4">
          <IonItem lines="none">
            <IonInput
            label='Fan Cycling' labelPlacement='floating'
              className={errors.building_fan_cycle === "" ? "valid" : "ion-invalid ion-touched"}
              aria-labelledby="Fan Cycling"
              value={form.building_fan_cycle}
              placeholder="Fan Cycling"
              onIonInput={(e: any) =>
                handleFormChange(e.detail.value!, "building_fan_cycle")
              }
              errorText={errors.building_fan_cycle}
            />
          </IonItem>
        </IonCol>
        <IonCol size="6" sizeMd="4">
          <IonItem lines="none">
            <IonInput
            label='Fan Cycling Start Date' labelPlacement='floating'
              className={errors.fc_start_date === "" ? "valid" : "ion-invalid ion-touched"}
              aria-labelledby="Fan Cycling Date"
              type="date"
              value={form.fc_start_date}
              placeholder="Start Date"
              onIonInput={(e: any) =>
                handleFormChange(e.detail.value!, "fc_start_date")
              }
              errorText={errors.fc_start_date}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        {/* Toral Project Fee */}
        <IonCol size="6" sizeMd="4">
          <IonItem lines="none">
            <IonInput
              label='Total Project Fee' labelPlacement='floating'
              className={errors.project_fee === "" ? "valid" : "ion-invalid ion-touched"}
              aria-labelledby="Total Project Fee"
              type="number"
              value={form.project_fee}
              placeholder="Total Project Fee"
              onIonInput={(e: any) =>
                handleFormChange(e.detail.value!, "project_fee")
              }
              errorText={errors.project_fee}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        {/* FOA Boolean*/}
        <IonCol size="6" sizeMd="3">
          <IonItem lines="full">
            <IonCheckbox
              checked={form.foa}
              mode="ios"
              slot="end"
              aria-label="FOA"
              onIonChange={(e: any) =>
                handleFormChange(e.detail.checked, "foa")
              }
            >
              FOA:
            </IonCheckbox>
          </IonItem>
        </IonCol>
        {/* KPI Boolean*/}
        <IonCol size="6" sizeMd="3">
          <IonItem lines="full">
            <IonCheckbox
              checked={form.kpi}
              mode="ios"
              slot="end"
              aria-label="KPI"
              onIonChange={(e: any) =>
                handleFormChange(e.detail.checked, "kpi")
              }
            >
              KPI:
            </IonCheckbox>
          </IonItem>
        </IonCol>
        {/* Campus Optimizer */}
        <IonCol size="6" sizeMd="3">
          <IonItem lines="full">
            <IonCheckbox
              checked={form.campus_optimizer}
              mode="ios"
              slot="end"
              aria-label="campus optimizer"
              onIonChange={(e: any) =>
                handleFormChange(e.detail.checked, "campus_optimizer")
              }
            >
              Campus Optimizer:
            </IonCheckbox>
          </IonItem>
        </IonCol>
        <IonCol size="6" sizeMd="3" hidden={!form.campus_optimizer}>
          <IonItem lines="none">
            <IonInput
            label='Campus Optimizer Star Date' labelPlacement='floating'
              className={errors.co_start_date === "" ? "valid" : "ion-invalid ion-touched"}
              type="date"
              aria-labelledby="campus optimizer date"
              value={form.co_start_date}
              placeholder="Start Date"
              onIonInput={(e: any) =>
                handleFormChange(e.detail.value!, "co_start_date")
              }
              errorText={errors.co_start_date}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonItem lines="none">
            <IDSearchSelectInput
              dict={primaryfunctions}
              dict_key={"id"}
              value={"name"}
              label="Primary Function"
              errors={errors.building_type}
              form={form}
              handleFormChange={handleFormChange}
              formName={"building_type"}
              placeholder={"Primary Function"}
            />
          </IonItem>
        </IonCol>
        {Number(form.building_type) === 30 ||
        Number(form.building_type) === 48 ? (
          <IonCol>
            <IonItem>
              <div style={{width:'100%'}}>
              <IonSelect
                className={
                  errors.building_sub_type === "" ? "valid" : "ion-invalid ion-touched"
                }
                label="Sub-Type"
                labelPlacement="floating"
                interface="popover"
                value={form.building_sub_type}
                placeholder="Sub Property Type"
                onIonChange={(e: any) =>
                  handleFormChange(e.detail.value!, "building_sub_type")
                }
              >
                {subTypes.map((item: types, index) => {
                  if (item.property_type === Number(form.building_type)) {
                    return (
                      <IonSelectOption key={index} value={item.uuid}>
                        {item.sub_type_name}
                      </IonSelectOption>
                    );
                  } else {
                    return <>""</>;
                  }
                })}
              </IonSelect>
              <div className="error-detail">{errors.building_sub_type}</div>
              </div>
            </IonItem>
          </IonCol>
        ) : (
          ""
        )}
      </IonRow>
      <IonItem disabled={form.add_estar}>
        <IonCheckbox
          checked={form.in_estar}
          mode="ios"
          slot="start"
          aria-label="Property Already In Energy star"
          onIonChange={(e: any) =>
            handleFormChange(e.detail.checked, "in_estar")
          }
        >
          Property Already in Energy Star
        </IonCheckbox>
      </IonItem>
      {form.in_estar ? (
        <IonItem>
          <IonInput
          label='Energy Star ID' labelPlacement='floating'
            type="number"
            className={errors.estar_id === "" ? "valid" : "ion-invalid ion-touched"}
            aria-labelledby="Energy Star ID"
            value={form.estar_id}
            placeholder="ID"
            onIonChange={(e: any) =>
              handleFormChange(e.detail.value!, "estar_id")
            }
            errorText={errors.estar_id}
          ></IonInput>
        </IonItem>
      ) : (
        ""
      )}
      <IonItem disabled={form.in_estar}>
        <IonCheckbox
          checked={form.add_estar}
          aria-label="Add To Energy Star"
          mode="ios"
          slot="start"
          onIonChange={(e: any) =>
            handleFormChange(e.detail.checked, "add_estar")
          }
        >
          ADD Property to EnergyStar
        </IonCheckbox>
      </IonItem>

      {/* Energy Start Form Data Needed */}
      {form.add_estar ? (
        <>
          <h3>Energy Star Form</h3>
          <p>
            Fill out the rest of the form to add the property to Energy Star.
          </p>
          <IonRow>
            <IonCol>
              <IonItem lines="none">
                <SearchSelectInput
                  list={country_choices}
                  errors={errors.estar_country}
                  label="country"
                  form={form.estar_country}
                  handleFormChange={handleFormChange}
                  formName={"estar_country"}
                  placeholder={"Country"}
                />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none">
                <IonInput
                label='Year Built' labelPlacement='floating'
                  className={
                    errors.estar_year_built === "" ? "valid" : "ion-invalid ion-touched"
                  }
                  errorText={errors.estar_year_built}
                  type="number"
                  aria-labelledby="year built"
                  value={form.estar_year_built}
                  placeholder={`${getCurrentYear()}`}
                  max={getCurrentYear()}
                  min={1800}
                  onIonInput={(e: any) =>
                    handleFormChange(e.detail.value!, "estar_year_built")
                  }
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none">
                <SearchSelectInput
                  list={construction_choices}
                  errors={errors.estar_construction_status}
                  label="Construction Status"
                  form={form.estar_construction_status}
                  handleFormChange={handleFormChange}
                  formName={"estar_construction_status"}
                  placeholder={"Construction Status"}
                />
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none">
                <SearchSelectInput
                  list={occupancy_choices}
                  errors={errors.estar_occupancy}
                  label="occupancy"
                  form={form.estar_occupancy}
                  handleFormChange={handleFormChange}
                  formName={"estar_occupancy"}
                  placeholder={"Occupancy"}
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem lines="none">
                <IonInput
                label='Number of Buildings' labelPlacement='floating'
                  className={
                    errors.number_of_buildings === "" ? "valid" : "ion-invalid ion-touched"
                  }
                  aria-labelledby="Number of buildings"
                  type="number"
                  value={form.number_of_buildings}
                  onIonInput={(e: any) =>
                    handleFormChange(e.detail.value!, "number_of_buildings")
                  }
                  errorText={errors.number_of_buildings}
                ></IonInput>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonItem lines="none">
                <IonCheckbox
                  checked={form.estar_is_federal_property}
                  mode="ios"
                  slot="start"
                  aria-label="Federal Property"
                  onIonChange={(e: any) =>
                    handleFormChange(
                      e.detail.checked,
                      "estar_is_federal_property"
                    )
                  }
                >
                  Federal Property?
                </IonCheckbox>
              </IonItem>
            </IonCol>
            {form.estar_is_federal_property ? (
              <IonCol>
                <IonItem lines="none">
                  <div style={{width:'100%'}}>
                  <IonSelect
                    className={
                      errors.estar_federal_owner === "" ? "valid" : "ion-invalid ion-touched"
                    }
                    label="Federal Owner"
                    labelPlacement="floating"
                    aria-label="federal owner"
                    value={form.estar_federal_owner}
                    interface="popover"
                    placeholder="Federal Owner"
                    onIonChange={(e: any) =>
                      handleFormChange(e.detail.value!, "estar_federal_owner")
                    }
                  >
                    <IonSelectOption value="US">United States</IonSelectOption>
                    <IonSelectOption value="CA">Canada</IonSelectOption>
                  </IonSelect>
                  <div className="error-detail">
                    {errors.estar_federal_owner}
                  </div>
                  </div>
                </IonItem>
              </IonCol>
            ) : undefined}
          </IonRow>
        </>
      ) : (
        " "
      )}

      <div className="button-container">
        <IonItem
          className="form-submit-button"
          button
          color="primary"
          detail={false}
          onClick={handleSubmit}
        >
          <IonLabel>Submit</IonLabel>
        </IonItem>
      </div>
    </form>
  );
};

export default PropertyForm;
