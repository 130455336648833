import React, { useEffect, useState } from "react";
import { IonButton, IonCol, IonItem, IonRow } from "@ionic/react";
import { Redirect } from "react-router";
import usePermissionGroups from "../../../utils/usePermissionGroups";
import { API_URL } from "../../../actions/settings";
import { Building } from "../../../interfaces/Building";
import useToggle from "../../../utils/useToggle";

async function getBuildings(token: any) {
  return fetch(`${API_URL}/imported_buildings`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

async function getResults(token: any) {
  return fetch(`${API_URL}/imported_buildings_results`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token,
    },
  }).then((data) => data.json());
}

const ImportedBuildings = () => {
  const { hasPermission } = usePermissionGroups();
  const [results, setResults] = useState<any>([]);
  const [resultsPage, toggle] = useToggle(true);

  const [buildings, setBuildings] = useState<any>([]);

  useEffect(() => {
    const handleData = async () => {
      const token = `Token ${(localStorage.getItem("token") || "").slice(
        1,
        -1
      )}`;
      const res = await getBuildings(token);
      if (res.status === "success") {
        setBuildings(res.data);
      } else {
        setBuildings([]);
      }
    };

    const handleResults = async () => {
      const token = `Token ${(localStorage.getItem("token") || "").slice(
        1,
        -1
      )}`;
      const res = await getResults(token);
      console.log(res);
      if (res.status === "success") {
        setResults(res.data);
      } else {
        setResults(null);
      }
    };
    handleResults();
    handleData();
  }, []);

  if (!hasPermission(["ViewEditImportedBuildings"])) {
    return <Redirect to="/403" />;
  }
  const date = new Date(results.date);

  return (
    <IonRow className="ion-justify-content-center">
      <IonCol sizeMd="5">
        <h1 style={{ textAlign: "center" }}>
          Recently Imported Buildings From Energy Star
        </h1>
        <div style={{ textAlign: "center" }}>
          <IonButton onClick={toggle}>
            {resultsPage ? "View Imported Buildings" : "View Results"}
          </IonButton>
        </div>

        {resultsPage ? (
          <div>
            <h3 style={{ textAlign: "center" }}>
              {" "}
              Results From {date.toDateString()}{" "}
            </h3>
            {results === null ? (
              <div> Error Getting the Imported Building Results. </div>
            ) : results.length === 0 ? (
              <div>No Latest Results Found </div>
            ) : (
              <IonRow style={{ border: "solid black 3px" }}>
                <IonCol>
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        borderBottom: "solid Green 2px",
                      }}
                    >
                      {" "}
                      Recently Added:
                    </h3>
                    <ul>
                      {results["passed"].map((res: any) => (
                        <li>
                          <div>{res.property.name} </div>{" "}
                          <div> ID: {res.property.id} </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </IonCol>
                <IonCol
                  style={{
                    borderRight: "solid black 3px",
                    borderLeft: "solid black 3px",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        borderBottom: "solid yellow 2px",
                      }}
                    >
                      {" "}
                      Missing Custom ID (UUID)
                    </h3>
                    <ul>
                      {results["Missing_ID"].map((res: any) => (
                        <li>
                          <div>{res.property.name} </div>{" "}
                          <div> ID: {res.property.id} </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </IonCol>
                <IonCol>
                  <div>
                    <h3
                      style={{
                        textAlign: "center",
                        borderBottom: "solid red 2px",
                      }}
                    >
                      {" "}
                      Failed:
                    </h3>
                    <ul>
                      {results["failed"].map((res: any) => (
                        <li>ID: {res.property}</li>
                      ))}
                    </ul>
                  </div>
                </IonCol>
              </IonRow>
            )}
          </div>
        ) : (
          <div>
            <h5 style={{ textAlign: "center" }}>
              {" "}
              Edit the Customer, County, Incentive, Fan Cycling, FOA, KPI, and
              Campus Optimizer
            </h5>
            {buildings.length > 0
              ? buildings.map((b: Building) => {
                  return (
                    <IonItem key={b.uuid} href={`building/${b.uuid}/edit`}>
                      {b.building_name} - {b.building_address}
                    </IonItem>
                  );
                })
              : "No New Buildings"}
          </div>
        )}
      </IonCol>
    </IonRow>
  );
};

export default ImportedBuildings;
